/**
=========================================================
* Crypto Champs React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  // Paper,
  TablePagination,
  // List,
  // ListItem,
  Avatar,
  FormControl,
  NativeSelect,
  TextField,
  Autocomplete,
} from "@mui/material";
import { styled } from "@mui/system";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";
// import { useNavigate } from "react-router-dom";
// import { SelectedstocksData } from "services/api/gameAPI";
// import { searchUser } from "services/api/gameAPI";
// import { useStyles } from "layouts/tokenDistribution";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useNavigate } from "react-router-dom";
// import ProgressLoading from "components/ProgressLoading/ProgressLoading";
import { io } from "socket.io-client";
import { CommonData } from "constant/common";
import AlertConfirm from "../../../components/AlertConfirm";
import * as apiService from "../../../services/api/gameAPI";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

function SelectedStock() {
  const [stocks, setStocks] = useState([]);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [count, setCount] = useState(0);
  const [term, setTerm] = useState("");
  const [query, setQuery] = useState("");

  const [action, setAction] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [stockId, setStockId] = useState([]);
  const [stockActionLoading, setStockActionLoading] = useState(false);
  const [filterText, setFilterText] = useState(null);

  const navigate = useNavigate();

  // const [loading, setLoading] = useState(false);

  // useEffect(() => {
  //   const fetchData = async () => {
  //     let response = [];
  //     try {
  //       response = await SelectedStockData({ page: page + 1, limit, term });
  //       console.log(response.data, "dd");
  //       setStocks(response.data.data.stocks);

  //       setCount(response.data.data.total);
  //       setLoading(false);
  //     } catch (error) {
  //       console.error(error);
  //     }
  //   };

  //   fetchData();
  // }, [page, limit, term]);

  useEffect(() => {
    const socket = io("wss://api.cryptochamps.io/stocks");
    // const socket = io(CommonData.stocksocket);
    // const socket = io("wss://dev-staging-api.cryptochamps.io/stocks");
    // const socket = io("http://localhost:3005/stocks");

    let requestData = {};
    // Emit the JSON body
    requestData = {
      page: page + 1,
      limit: limit,
      isDisabled: false,
      title: term,
    };

    if (filterText) {
      requestData = {
        ...requestData,
        filterText: filterText,
      };
    }

    console.log(requestData);

    const fetchDataAndUpdate = (socket, requestData) => {
      socket.emit("GETALL_STOCK_ADMIN", requestData);

      socket.on("GETALL_STOCK_ADMIN", (ackData) => {
        // console.log("Received live data update:", ackData);
        setStocks(ackData.stocks);

        setCount(ackData.total);
      });
    };
    // Initial fetch of data
    fetchDataAndUpdate(socket, requestData);
    // Fetch data every 2 seconds
    const intervalId = setInterval(() => {
      fetchDataAndUpdate(socket, requestData);
    }, 2000);

    // Clean up the socket connection
    return () => {
      clearInterval(intervalId);
      socket.disconnect();
    };
  }, [limit, page, term, filterText]);

  console.log(stocks, "RES");

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };
  const handleTitle = async (e) => {
    const newQuery = e.target.value;

    setTerm(newQuery);
    setQuery(newQuery);
    setPage(0);
    console.log(query);
  };

  const submit = (id) => {
    setAlertOpen(true);
    setStockId(id);
    setStockActionLoading(false);
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      {/* {loading ? (
        <ProgressLoading />
      ) : ( */}
      <>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Card>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "12px -1px",
                }}
              >
                <MDBox margin="20px" pr={1} display="flex" gap={10}>
                  <MDInput label="Search stocks" value={query} onChange={handleTitle} />

                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    sx={{ width: 160 }}
                    options={["None", "Disable", "Enable"]}
                    getOptionLabel={(option) => `${option}`}
                    onChange={(event, newValue) => {
                      // onChange(Number(newValue?.label) || "");
                      // setWinnerType(newValue);
                      // setTrigger(!trigger);
                      console.log(newValue);
                      if (newValue === "None") setFilterText(null);
                      else setFilterText(newValue);
                      //setting type value to state for validating the stocks count
                    }}
                    value={filterText ?? "None"}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        id="outlined-basic"
                        label="Filter"
                        variant="outlined"
                        sx={{
                          "& .MuiInputBase-root": {
                            height: 45,
                          },
                        }}
                      />
                    )}
                  />
                </MDBox>

                <MDTypography component="label" style={{ marginRight: "120px" }}></MDTypography>

                <div style={{ margin: "20px" }}>
                  {/* {stocks.length !== 0 ? ( */}
                  {/* <MDButton
                        variant="contained"
                        onClick={() => navigate("/addstocks")}
                        color="primary"
                      >
                        Add stocks
                      </MDButton> */}
                  {/* ) : (
                        <div></div>
                      )} */}
                  {/* <MDButton
                    variant="contained"
                    onClick={() => navigate("/inactive-stocks")}
                    color="info"
                    sx={{
                      fontSize: 10,
                    }}
                  >
                    View Last 24H Inactive stocks
                  </MDButton> */}
                </div>
              </div>
              {/* {query === "" ? (
                      ""
                    ) : ( */}
              {/* <div id="search-card" className={classes.searchCard}>
                      <Paper>
                        <List id="suggestion-list">
                          {suggestions.map((suggestion) => (
                            <ListItem
                              key={suggestion._id}
                              button
                              className={classes.suggestionItem}
                              // onClick={() => handleSuggestionClick(suggestion)}
                            >
                              {suggestion?.name}
                            </ListItem>
                          ))}
                        </List>
                      </Paper>
                    </div> */}
              {/* )} */}

              <TableContainer>
                {stocks?.length === 0 ? (
                  <div
                    style={{
                      color: "#FFF",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "100%",
                      height: "300px",
                    }}
                  >
                    There are no records at the moment.
                  </div>
                ) : (
                  <Table>
                    <StyledTableHead>
                      <TableRow>
                        <StyledTableCell>Name</StyledTableCell>
                        <StyledTableCell>Symbol</StyledTableCell>
                        <StyledTableCell> Price</StyledTableCell>
                        <StyledTableCell>Price Change Percentage 24hr</StyledTableCell>
                        <StyledTableCell>Market Capitalization</StyledTableCell>
                      </TableRow>
                    </StyledTableHead>

                    <TableBody>
                      {stocks?.map((data) => (
                        <StyledTableRow key={data._id}>
                          <StyledTableCell sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                            <Avatar src={data.image} />
                            {data.name}
                          </StyledTableCell>
                          <StyledTableCell>{data.symbol}</StyledTableCell>
                          <StyledTableCell>${data.price}</StyledTableCell>
                          <StyledTableCell>{data.price_change_percentage_24h}%</StyledTableCell>
                          <StyledTableCell>{data?.market_capitalization ?? 0}</StyledTableCell>
                          <StyledTableCell>
                            {data.isDisabled ? (
                              <MDButton
                                color="success"
                                onClick={() => {
                                  submit(data._id);
                                  setAction("enable");
                                }}
                              >
                                Enable
                              </MDButton>
                            ) : (
                              <MDButton
                                color="error"
                                onClick={() => {
                                  submit(data._id);
                                  setAction("disable");
                                }}
                              >
                                Disable
                              </MDButton>
                            )}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </TableContainer>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              count={count}
              labelRowsPerPage="Rows per page:"
              style={{ color: "white" }} // Change the color here
              rowsPerPage={limit}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
            />
          </Grid>
        </Grid>
        <Footer />
      </>
      {/* )} */}

      <AlertConfirm
        open={alertOpen}
        title="Create"
        context={`Do you want ${action} stock?`}
        onClose={() => setAlertOpen(!alertOpen)}
        loading={stockActionLoading}
        onAccept={async () => {
          setStockActionLoading(true);
          try {
            const response = await apiService.disableStocks({ id: stockId });

            if (response?.data?.error) {
              setStockActionLoading(false);
              return alert(response?.data?.error);
            }

            if (response.data) {
              if (response?.data?.isDisabled) {
                setAction("enable");
              } else {
                setAction("disable");
              }

              setAlertOpen(false);
              alert("Stock status changed successfully");
              // setTimeout(() => {
              //   window.location.reload();
              // }, 300);
            }
          } catch (error) {
            console.log(error);
            alert("Something went wrong");
          }
          setStockActionLoading(false);
        }}
      />
    </DashboardLayout>
  );
}
export default SelectedStock;
