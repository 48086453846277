/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import { Box, Button, Card, Container, FormControl, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import MDInput from "components/MDInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import React, { useEffect, useState } from "react";
import { styled } from "@mui/system";
// import MDButton from "components/MDButton";
// import { sendReward } from "services/api/gameAPI";
// import { searchUser } from "services/api/gameAPI";
import { getBlockChianConfig } from "services/api/gameAPI";
import { CreateBlockChianConfig } from "services/api/gameAPI";
import TokenConfig from "components/TokenConfig/TokenConfig";
import { CreateReferralConfig } from "services/api/gameAPI";
import { getReferralConfig } from "services/api/gameAPI";
import { StockBlockChianConfig } from "services/api/gameAPI";

export const useStyles = styled((theme) => ({
  searchCard: {
    // Add your custom styling here
    marginTop: "20px",
  },
  suggestionItem: {
    // Add your custom styling here
    padding: "10px",
    cursor: "pointer",
    backgroundColor: "#f5f5f5",

    "&:hover": {
      backgroundColor: "#ebebeb",
    },
  },
}));

// const walletAmount = 10000;
function WalletConfiguration() {
  const [wallet, setWallet] = useState(
    ""
    // "0xaed1f6221c4920f49d786e79f4fff33da5646fa1464ed3e42bb3644c9a6c8d6f"
  );
  const [chainId, setChainId] = useState("");
  const [adminWallet, setAdminWallet] = useState("");
  const [miniToken, setMiniToken] = useState("");
  const [paymentTokens, setPaymentTokens] = useState();
  const [configId, seConfigId] = useState();
  const [personalWalletAddress, setPersonalWalletAddress] = useState("");
  const [treasuryWalletAddress, setTreasuryWalletAddress] = useState("");
  const [transactionCharge, setTransactionCharge] = useState("");

  const [referralCount, setReferralCount] = useState("");
  const [referralReward, setReferralReward] = useState("");
  // Ambassador
  const [ambassadorReferralCount, setAmbassadorReferralCount] = useState(0);
  const [ambassadorSpentUSDC, setAmbassadorSpentUSDC] = useState(0);
  const [ambassadorCommission, setAmbassadorCommission] = useState(0);

  const [adminProfitPercentage, setAdminProfitPercentage] = useState(0);
  const [treasuryWalPercentage, setTreasuryWalPercentage] = useState(0);
  const [personalWalPercentage, setPersonalWalPercentage] = useState(0);

  // Stocks
  const [stockSCAddress, setStockSCAddress] = useState("");
  const [stockAdminAddress, setStockAdminAddress] = useState("");
  const [stockPlatformAddress, setStockPlatformAddress] = useState("");

  // let navigate = useNavigate();
  React.useEffect(() => {
    const fetchReferralCount = async () => {
      try {
        const res = await getReferralConfig();
        console.log(res?.data, "res");
        setReferralCount(res.data?.data?.referralCount);
        setReferralReward(res.data?.data?.referralReward);

        const ambassadorConfig = res.data?.data?.referralConfig;

        setAmbassadorReferralCount(ambassadorConfig?.aReferralCount);
        setAmbassadorSpentUSDC(ambassadorConfig?.aMinSpentAmount);
        setAmbassadorCommission(ambassadorConfig?.aMinPctAmount);
      } catch (error) {
        console.log(error);
      }
    };
    const fetchWallet = async () => {
      try {
        const res = await getBlockChianConfig();
        console.log(res);
        seConfigId(res.data?._id);
        setChainId(res.data?.chainId);
        setWallet(res.data?.contractAddress);
        setAdminWallet(res.data?.adminWallet);
        setMiniToken(res.data?.MiniToken);
        setPaymentTokens(res.data?.paymentMethod);
        setTransactionCharge(res.data?.transactionCharge);
        setPersonalWalletAddress(res.data?.personalWalletAddress);
        setTreasuryWalletAddress(res.data?.treasuryWalletAddress);
        setAdminProfitPercentage(res.data?.adminProfitPercentage);
        setTreasuryWalPercentage(res.data?.treasuryWalPct);
        setPersonalWalPercentage(res.data?.personalWalPct);

        // Stocks
        setStockSCAddress(res?.data?.stockGameSc?.contractAddress);

        fetchReferralCount();
      } catch (error) {
        console.error(error);
      }
    };
    fetchWallet();
  }, []);

  const onSubmit = async () => {
    try {
      const data = {
        contractAddress: wallet,
        chainId: Number(chainId),
        adminWallet: adminWallet,
        MiniToken: miniToken,
        transactionCharge: Number(transactionCharge),
        personalWalletAddress: personalWalletAddress,
        treasuryWalletAddress: treasuryWalletAddress,
      };
      const res = await CreateBlockChianConfig(data);
      if (res) {
        alert("Success! Your wallet settings have been updated.");
      }
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        alert("Oops! Something went wrong. Unable to update wallet settings at this time.");
      } else {
        alert("Oops! Something went wrong. Unable to update wallet settings at this time.");
        console.log("err", error);
      }
    }
  };

  const handleGameReferralConfig = async () => {
    try {
      const data = {
        referralCount: Number(referralCount),
        referralReward: Number(referralReward),
        referralConfig: {
          aReferralCount: Number(ambassadorReferralCount),
          aMinSpentAmount: Number(ambassadorSpentUSDC),
          aMinPctAmount: Number(ambassadorCommission),
        },
      };

      console.log("payload", data);
      const res = await CreateReferralConfig(data);

      if (res) {
        alert("Success! Referral settings have been updated.");
      }
    } catch (error) {
      alert("Oops! Something went wrong. Unable to update referral settings at this time.");
    }
  };

  const handleGameProfitPercentage = async () => {
    try {
      const treasuryPct = parseInt(treasuryWalPercentage);
      const personalPct = parseInt(personalWalPercentage);
      const adminProfitPct = parseInt(adminProfitPercentage);

      const totalPercentage = treasuryPct + personalPct;

      // if (treasuryPct <= 0 || personalPct <= 0 || adminProfitPct <= 0) {
      //   return alert("All percentage values must be greater than zero.");
      // }

      if (adminProfitPct != totalPercentage) {
        return alert(
          "The sum of Treasury and Personal percentages must equal the Admin Profit percentage."
        );
      }
      const data = {
        adminProfitPercentage: adminProfitPct,
        treasuryWalPct: treasuryPct, //treasury wallet percentage
        personalWalPct: personalPct, //personal wallet percentage
      };

      const res = await CreateBlockChianConfig(data);
      if (res?.data) {
        alert("Success! Admin Profit percentage settings have been updated.");
      }
    } catch (error) {
      console.log(error?.response);
      alert("Oops! Something went wrong. Unable to update game profit settings at this time.");
    }
  };

  // const classes = useStyles();
  async function handleInputChange(event) {
    const newQuery = event.target.value;
    setWallet(newQuery);
  }
  async function handleInputChangeOne(event) {
    const newQuery = event.target.value;
    setChainId(newQuery);
  }
  async function handleInputChangeTwo(event) {
    const newQuery = event.target.value;
    setAdminWallet(newQuery);
  }
  async function handleInputChangeThree(event) {
    const newQuery = event.target.value;
    setMiniToken(newQuery);
  }
  async function handleTransactonCharge(event) {
    const newQuery = event.target.value;
    setTransactionCharge(newQuery);
  }
  async function handlePersonalWallet(event) {
    const newQuery = event.target.value;
    setPersonalWalletAddress(newQuery);
  }
  async function handleTreasuryWallet(event) {
    const newQuery = event.target.value;
    setTreasuryWalletAddress(newQuery);
  }

  async function handleReferralCount(event) {
    const newQuery = event.target.value;
    setReferralCount(newQuery);
  }

  async function handleReferralReward(event) {
    const newQuery = event.target.value;
    setReferralReward(newQuery);
  }

  async function handleAmbassadorReferralCount(event) {
    const newQuery = event.target.value;
    setAmbassadorReferralCount(newQuery);
  }

  async function handleAmbassadorSpentUSDC(event) {
    const newQuery = event.target.value;
    setAmbassadorSpentUSDC(newQuery);
  }

  async function handleAmbassadorCommission(event) {
    const newQuery = event.target.value;
    setAmbassadorCommission(newQuery);
  }

  async function handleAdminProfitPercentage(event) {
    const newQuery = event.target.value;
    setAdminProfitPercentage(newQuery);
  }
  async function handleTreasuryWalPercentage(event) {
    const newQuery = event.target.value;
    setTreasuryWalPercentage(newQuery);
  }
  async function handlePersonalWalPercentage(event) {
    const newQuery = event.target.value;
    setPersonalWalPercentage(newQuery);
  }

  // ===========[STOCKS] =============

  async function handleStockSCAddress(event) {
    const newQuery = event.target.value;
    setStockSCAddress(newQuery);
  }
  async function handleStockAdminAddress(event) {
    const newQuery = event.target.value;
    setStockAdminAddress(newQuery);
  }
  async function handleStockPlatformAddress(event) {
    const newQuery = event.target.value;
    setStockPlatformAddress(newQuery);
  }

  const handleStockScSubmit = async () => {
    try {
      const data = {
        contractAddress: stockSCAddress,
      };
      const res = await StockBlockChianConfig(data);
      if (res) {
        alert("Success! Your wallet settings have been updated.");
      }
    } catch (error) {
      if (error.response && (error.response.status === 401 || error.response.status === 403)) {
        alert("Oops! Something went wrong. Unable to update wallet settings at this time.");
      } else {
        alert("Oops! Something went wrong. Unable to update wallet settings at this time.");
        console.error("err", error);
      }
    }
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <Container maxWidth="sm">
        <Card elevation={3} sx={{ mt: 4, p: 4 }}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <FormControl fullWidth>
              <MDInput
                label="Smart Contract Wallet Address"
                value={wallet}
                size="small"
                // placeholder="Input user"
                onChange={handleInputChange}
              />
            </FormControl>
            <FormControl fullWidth>
              <MDInput
                label="Chain ID"
                value={chainId}
                size="small"
                // placeholder="Input user"
                onChange={handleInputChangeOne}
              />
            </FormControl>
            <FormControl fullWidth>
              <MDInput
                label="Admin wallet Address"
                value={adminWallet}
                size="small"
                // placeholder="Input user"
                onChange={handleInputChangeTwo}
              />
            </FormControl>
            <FormControl fullWidth>
              <MDInput
                label="Withdraw minimum token"
                value={miniToken}
                size="small"
                // placeholder="Input user"
                onChange={handleInputChangeThree}
              />
            </FormControl>
            <FormControl fullWidth>
              <MDInput
                label="Transaction charge"
                value={Number(transactionCharge)}
                size="small"
                // placeholder="Input user"
                onChange={handleTransactonCharge}
              />
            </FormControl>
            {/* admin config */}
            <Typography variant="h6">Admin Withdrawal Details</Typography>
            <FormControl fullWidth>
              <MDInput
                label="Personal Wallet Address"
                value={personalWalletAddress}
                size="small"
                // placeholder="Input user"
                onChange={handlePersonalWallet}
              />
            </FormControl>
            <FormControl fullWidth>
              <MDInput
                label="Treasury Wallet Address"
                value={treasuryWalletAddress}
                size="small"
                // placeholder="Input user"
                onChange={handleTreasuryWallet}
              />
            </FormControl>
            <Box textAlign="center">
              <Button variant="contained" color="primary" onClick={onSubmit}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>

      {/* Stocks Management */}
      <Container maxWidth="sm">
        <Card elevation={3} sx={{ mt: 4, p: 4 }}>
          <Stack spacing={2} sx={{ mt: 2 }}>
            <Typography variant="h6">Stocks</Typography>
            <FormControl fullWidth>
              <MDInput
                label="Smart Contract Wallet Address"
                value={stockSCAddress}
                size="small"
                // placeholder="Input user"
                onChange={handleStockSCAddress}
              />
            </FormControl>
            {/* <FormControl fullWidth>
              <MDInput
                label="Admin wallet Address"
                value={stockAdminAddress}
                size="small"
                // placeholder="Input user"
                onChange={handleStockAdminAddress}
              />
            </FormControl>
            <FormControl fullWidth disabled>
              <MDInput
                label="Platform wallet Address"
                value={stockPlatformAddress}
                size="small"
                // placeholder="Input user"
                disabled
                onChange={handleStockPlatformAddress}
              />
            </FormControl> */}
            <Box textAlign="center">
              <Button variant="contained" color="primary" onClick={handleStockScSubmit}>
                Submit
              </Button>
            </Box>
          </Stack>
        </Card>
      </Container>
      <Container maxWidth="sm">
        <Card elevation={3} sx={{ mt: 4, p: 4, display: "flex", flexDirection: "column", gap: 4 }}>
          <Typography variant="h6">Referral rules</Typography>
          <Stack
            direction={"row"}
            alignItems={"center"}
            sx={{
              color: "#ffff",
              fontSize: 18,
              gap: 1,
            }}
          >
            Invite
            <FormControl>
              <MDInput
                type={"number"}
                value={referralCount}
                size="small"
                sx={{
                  maxWidth: 60,
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // placeholder="Input user"
                onChange={handleReferralCount}
              />
            </FormControl>{" "}
            Users and get{" "}
            <FormControl>
              <MDInput
                type={"number"}
                value={referralReward}
                size="small"
                sx={{
                  maxWidth: 60,
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // placeholder="Input user"
                onChange={handleReferralReward}
              />
            </FormControl>
            Champs
          </Stack>
          <Stack spacing={2}>
            <FormControl>
              <MDInput
                label="Ambassador referral count"
                type={"number"}
                value={ambassadorReferralCount}
                size="small"
                sx={{
                  // maxWidth: 60,
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // placeholder="Input user"
                onChange={handleAmbassadorReferralCount}
              />
            </FormControl>
            <FormControl>
              <MDInput
                label="Ambassador spent usdc"
                type={"number"}
                value={ambassadorSpentUSDC}
                size="small"
                sx={{
                  // maxWidth: 60,
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // placeholder="Input user"
                onChange={handleAmbassadorSpentUSDC}
                // onChange={(event) => {
                //   console.log(event.target.value);
                //   const newQuery = event.target.value;
                //   setAmbassadorSpentUSDC(newQuery);
                // }}
              />
            </FormControl>
            <FormControl>
              <MDInput
                label="Ambassador commission"
                type={"number"}
                value={ambassadorCommission}
                size="small"
                sx={{
                  // maxWidth: 60,
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // placeholder="Input user"
                onChange={handleAmbassadorCommission}
              />
            </FormControl>
          </Stack>

          <Box textAlign="center">
            <Button variant="contained" color="primary" onClick={handleGameReferralConfig}>
              Submit
            </Button>
          </Box>
        </Card>
      </Container>
      <Container maxWidth="sm">
        <Card elevation={3} sx={{ mt: 4, p: 4, display: "flex", flexDirection: "column", gap: 4 }}>
          <Typography variant="h6">Game profit percentage</Typography>
          <Stack spacing={2}>
            <FormControl>
              <MDInput
                label="Admin profit percentage"
                type={"number"}
                value={adminProfitPercentage}
                size="small"
                sx={{
                  // maxWidth: 60,
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // placeholder="Input user"
                onChange={handleAdminProfitPercentage}
              />
            </FormControl>
            <FormControl>
              <MDInput
                label="Treasury wallet percentage"
                type={"number"}
                value={treasuryWalPercentage}
                size="small"
                sx={{
                  // maxWidth: 60,
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // placeholder="Input user"
                onChange={handleTreasuryWalPercentage}
              />
            </FormControl>
            <FormControl>
              <MDInput
                label="Personal wallet percentage"
                type={"number"}
                value={personalWalPercentage}
                size="small"
                sx={{
                  // maxWidth: 60,
                  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
                    display: "none",
                  },
                  "& input[type=number]": {
                    MozAppearance: "textfield",
                  },
                }}
                // placeholder="Input user"
                onChange={handlePersonalWalPercentage}
              />
            </FormControl>
          </Stack>
          <Box textAlign="center">
            <Button variant="contained" color="primary" onClick={handleGameProfitPercentage}>
              Submit
            </Button>
          </Box>
        </Card>
      </Container>
      <TokenConfig
        configId={configId}
        paymentTokens={paymentTokens}
        setPaymentTokens={setPaymentTokens}
      />
    </DashboardLayout>
  );
}

export default WalletConfiguration;
