import { Button, Card, Grid, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import AlertConfirm from "components/AlertConfirm";
import { addInstance } from "services/api/gameAPI";
import { format } from "date-fns";
import { CommonData } from "constant/common";

const style = {
  position: "absolute",
  top: { xs: "25%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, sm: 400, lg: 400 },
  // bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  //   height: { xs: 300, sm: 350, md: 380, lg: 500 },
  overflowY: "scroll",
};

const AddInstance = ({ gameId, gameDuration, gamePaymentType }) => {
  const [open, setOpen] = useState(false);
  const [userInstance, setUserInstance] = useState("");
  const [duration, setDuration] = useState();
  const [alertOpen, setAlertOpen] = useState(false);
  const [date, setDate] = useState(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const createInstance = (id, gameD) => {
    setUserInstance(id);
    setDuration(gameD);
    setAlertOpen(true);
  };

  // const isDateDisabled = (date) => {
  //   const today = new Date();
  //   today.setDate(today.getDate() - 1);
  //   return date < today;
  // };

  console.log(process.env.REACT_APP_DEFAULT_TIME_ZONE);

  const isDateDisabled = (date) => {
    try {
      // Create a date object for the current time in NY timezone
      const nyTime = new Date().toLocaleString("en-US", {
        timeZone: `${process.env.REACT_APP_DEFAULT_TIME_ZONE}`,
        // timeZone: "America/New_York",
      });

      // Convert NY time string back to Date object
      const today = new Date(nyTime);

      // Reset time part to midnight to compare dates only
      today.setHours(0, 0, 0, 0);

      // Create a new date object from input date with time reset
      const compareDate = new Date(date);
      compareDate.setHours(0, 0, 0, 0);

      // Compare if the date is before today
      return compareDate < today;
    } catch (error) {
      console.log(error);
      const today = new Date();
      today.setDate(today.getDate() - 1);
      return date < today;
    }
  };

  return (
    <div>
      <Button onClick={handleOpen} variant="contained">
        Add
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Grid item xs={12}>
            <Card
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                gap: "20px",
                padding: 3,
              }}
            >
              <MDTypography
                variant="h5"
                color="white"
                p={1.5}
                sx={{ textAlign: "center", marginTop: "-21px" }}
              >
                Add Game Instance
              </MDTypography>

              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "start",
                      gap: 2,
                    }}
                  >
                    <Typography variant="body2" sx={{ color: "#fff" }}>
                      Please select game start date
                    </Typography>
                    <DatePicker
                      label="Game start date"
                      value={date}
                      // timezone="America/New_York"
                      // timezone={CommonData?.timezone}
                      // slotProps={{ popper: { placement: "right-end" } }}
                      shouldDisableDate={isDateDisabled}
                      onChange={(newValue) => {
                        setDate(newValue);
                      }}
                    />

                    <Button
                      onClick={() => createInstance(gameId, gameDuration)}
                      variant="contained"
                      sx={{ color: "white" }}
                    >
                      Submit
                    </Button>
                  </Box>
                </DemoContainer>
              </LocalizationProvider>
            </Card>
          </Grid>
        </Box>
      </Modal>

      <AlertConfirm
        isSubmitting={isSubmitting}
        open={alertOpen}
        title="Create"
        context="Do you want to create?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          setIsSubmitting(true);
          try {
            const yourDateObject = new Date(date);
            const formattedDate = format(yourDateObject, "yyyy-MM-dd");
            console.log(userInstance, formattedDate);
            const body = {
              gameId: userInstance,
              startDate: formattedDate,
              gameDuration: duration,
              gamePaymentType: gamePaymentType,
            };
            const instance = await addInstance(body);
            console.log(instance.data?.data);
            if (instance.data?.data === "waiting" || instance.data?.data === "started") {
              alert("Can't add Instance. Game Instance already added..!");
              setTimeout(() => {
                if (instance) {
                  return window.location.reload();
                }
              }, 200);
            } else {
              if (instance) {
                setTimeout(() => {
                  alert("Instance added successfully!");
                  return window.location.reload();
                }, 200);
              }
            }
          } catch (error) {
            if (error?.response?.data?.error?.code) {
              alert(error?.response?.data?.error?.reason);
            } else if (error?.response?.data?.error?.message) {
              alert(error?.response?.data?.error?.message);
            } else {
              alert("Error: Adding a new game instance failed.");
            }
            console.error("An error occurred:", error);
          }
          setIsSubmitting(false);
        }}
      />
    </div>
  );
};

export default AddInstance;
