import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  //   TablePagination,
} from "@mui/material";
import { styled } from "@mui/system";
import MDBox from "components/MDBox";
// import MDInput from "components/MDInput";
// import MDButton from "components/MDButton";
// import { Link } from "react-router-dom";
import { getGameList } from "services/api/gameAPI";
import { Link } from "react-router-dom";
import ViewEditGame from "./ViewEditGame";
// import { addInstance } from "services/api/gameAPI";
import { deleteGame } from "services/api/gameAPI";
import AlertConfirm from "components/AlertConfirm";
import AddInstance from "./AddInstance";
import { stopGeneratingGame } from "services/api/gameAPI";
import MDBadge from "components/MDBadge";
import FreeGameEdit from "./FreeGameEdit";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

// eslint-disable-next-line no-empty-pattern
const StyledTableHead = styled(TableHead)(({}) => ({
  display: "table-header-group",
}));

function GameList() {
  const [userId, setUserId] = useState("");
  // const [userInstance, setUserInstance] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  // const [alertOpenOne, setAlertOpenOne] = useState(false);
  const [stopGameAlert, setStopGameAlert] = useState(false);
  const [gameId, setGameId] = useState("");
  const [gameData, setGameData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [gameMode, setGameMode] = useState("");

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const games = await getGameList({ page: page + 1, limit });
        setGameData(games?.data?.data.games);
        setCount(games?.data?.data.total);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here if needed
      }
    };

    fetchData();
  }, [page, limit]);

  console.log(gameData, "gameData");

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
    setPage(0);
  };

  // const createInstance = (id) => {
  // setUserInstance(id);
  //   setAlertOpenOne(true);
  // };

  const stopGame = (id, mode) => {
    setGameId(id);
    setGameMode(mode);
    setStopGameAlert(true);
  };

  // const gameDelete = (id) =>{
  //   const deleted = deleteGame(id);
  //   if(deleted){
  //     window.location.reload()
  //   }

  // }

  const userHandle = (id) => {
    setAlertOpen(true);
    setUserId(id);
  };
  return (
    <>
      <Card>
        {loading ? (
          <p
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "70vh",
              minWidth: "70vw",
              color: "white",
              margin: 0,
            }}
          >
            Loading...
          </p>
        ) : (
          <div>
            <MDBox margin="20px" pr={1}>
              {/* <MDInput label="Search..." value={term} size="small" onChange={handletitle} /> */}
            </MDBox>
            <TableContainer>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell
                      sx={{
                        textWrap: "nowrap",
                      }}
                    >
                      Game Name
                    </StyledTableCell>
                    <StyledTableCell>GameAsset Type</StyledTableCell>
                    <StyledTableCell>Payment Type</StyledTableCell>
                    <StyledTableCell>Min Players</StyledTableCell>
                    <StyledTableCell>Max Players</StyledTableCell>

                    <StyledTableCell>Prize Money</StyledTableCell>
                    {/* <StyledTableCell>Entry fees</StyledTableCell> */}
                    <StyledTableCell>Game Frequency</StyledTableCell>
                    <StyledTableCell>Game Type</StyledTableCell>
                    <StyledTableCell>Game Category</StyledTableCell>
                    <StyledTableCell>Game Mode</StyledTableCell>
                    <StyledTableCell>Winner Type</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>Details</StyledTableCell>
                    {/* <StyledTableCell sx={{ textAlign: "center" }}>Bet Place</StyledTableCell> */}
                    <StyledTableCell sx={{ textAlign: "center" }}>Instances</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>Add Instance</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>Change Game Mode</StyledTableCell>
                    <StyledTableCell sx={{ textAlign: "center" }}>Delete Game</StyledTableCell>

                    {/* Add more table headers as needed */}
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {gameData?.map((game) => (
                    <StyledTableRow key={game?._id}>
                      <StyledTableCell>{game?.gameName} </StyledTableCell>
                      <StyledTableCell>
                        {game?.gameAssetType?.slice(0, game.gameAssetType?.length - 1)}{" "}
                      </StyledTableCell>
                      <StyledTableCell>
                        {" "}
                        <MDBadge
                          badgeContent={!game?.gamePaymentType ? "Paid" : game?.gamePaymentType}
                          color={game?.gamePaymentType === "Free" ? "info" : "success"}
                          variant="gradient"
                          size="sm"
                        />{" "}
                      </StyledTableCell>
                      <StyledTableCell>{game?.minPlayer}</StyledTableCell>
                      <StyledTableCell>{game?.maxPlayer}</StyledTableCell>

                      {/* <StyledTableCell>{game?.prizeMoney}</StyledTableCell> */}
                      <StyledTableCell>
                        {game?.entryFees * game?.minPlayer -
                          game?.entryFees * game?.minPlayer * 0.2}{" "}
                      </StyledTableCell>
                      <StyledTableCell>{game?.gameFrequency}</StyledTableCell>
                      <StyledTableCell>{game?.gameType}</StyledTableCell>
                      <StyledTableCell>{game?.gameCategory}</StyledTableCell>
                      <StyledTableCell>{game?.gameGenerate}</StyledTableCell>
                      <StyledTableCell>{game?.winnerType}</StyledTableCell>

                      <StyledTableCell>
                        {/* <MDButton color="info">
                    <Link to={`/profile/${game._id}`} state={{ List: game._id }}>
                      VIEW
                    </Link>
                  </MDButton> */}
                        {game?.gamePaymentType === "Free" ? (
                          <FreeGameEdit gameData={game} />
                        ) : (
                          <ViewEditGame game={game} />
                        )}
                      </StyledTableCell>

                      <StyledTableCell>
                        {/* <Link to={`/game-instance?name=${game?.gameName}&id=${game?._id}`}> */}
                        <Link to={`/game-instance?id=${game?._id}`}>
                          <Button variant="contained" sx={{ color: "white" }}>
                            View
                          </Button>
                        </Link>
                        {/* <ViewEditGame game={game} /> */}
                      </StyledTableCell>
                      <StyledTableCell>
                        {game.gameCategory !== "Head to Head" && !game?.isFrequently ? (
                          // <Button
                          //   onClick={() => createInstance(game?._id)}
                          //   variant="contained"
                          //   sx={{ color: "white" }}
                          // >
                          //   Add
                          // </Button>
                          <AddInstance
                            gameId={game?._id}
                            gameDuration={game?.gameDuration}
                            gamePaymentType={game?.gamePaymentType}
                          />
                        ) : (
                          ""
                        )}
                      </StyledTableCell>

                      <StyledTableCell>
                        {game?.gameGenerate === "One Time" ? (
                          <Button
                            onClick={() => stopGame(game._id, "Frequently")}
                            variant="contained"
                            sx={{ color: "white" }}
                            // disabled={game?.isFrequently === false}
                          >
                            Switch Frequently
                          </Button>
                        ) : (
                          <Button
                            onClick={() => stopGame(game._id, "One Time")}
                            variant="contained"
                            sx={{ color: "white" }}
                            // disabled={game?.isFrequently === false}
                          >
                            Switch One Time
                          </Button>
                        )}
                      </StyledTableCell>

                      <StyledTableCell>
                        <Button
                          onClick={() => userHandle(game._id)}
                          variant="contained"
                          disabled={game?.isEditable === false}
                          sx={{ color: "white" }}
                        >
                          Delete
                        </Button>
                      </StyledTableCell>
                      {/* Add more table cells for other user properties */}
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <TablePagination
              rowsPerPageOptions={[5, 10, 15]}
              component="div"
              style={{ color: "white" }}
              count={count}
              rowsPerPage={limit}
              page={page}
              onPageChange={handlePageChange}
              onRowsPerPageChange={handleLimitChange}
            />
          </div>
        )}
      </Card>
      {/* <AlertConfirm
        open={alertOpenOne}
        title="Create"
        context="Do you want to Create ?"
        onClose={() => setAlertOpenOne(!alertOpenOne)}
        onAccept={async () => {
          try {
            const instance = addInstance(userInstance);
            setTimeout(() => {
              if (instance) {
                window.location.reload();
              }
            }, 800);
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }}
      /> */}
      <AlertConfirm
        open={alertOpen}
        title="Delete"
        context="Are you sure want to delete this game?"
        onClose={() => setAlertOpen(!alertOpen)}
        onAccept={async () => {
          try {
            const response = await deleteGame(userId);
            if (response) {
              alert("Success! Game has been successfully deleted");
              setTimeout(() => {
                window.location.reload();
              }, 200);
            }
            // window.location.reload(false);
          } catch (error) {
            console.error("An error occurred:", error);
          }
        }}
      />
      <AlertConfirm
        open={stopGameAlert}
        title="Stop"
        context={`Do you want to change game mode to ${gameMode} ?`}
        onClose={() => setStopGameAlert(!stopGameAlert)}
        onAccept={async () => {
          try {
            const body = {
              gameId: gameId,
              gameGenerate: gameMode,
            };
            console.log(body);
            const response = await stopGeneratingGame(body);
            console.log(response.data);
            // window.location.reload(false);
            if (response) {
              alert(`Success! You've switched to the ${gameMode} mode. `);
              setTimeout(() => {
                window.location.reload();
              }, 200);
            }
          } catch (error) {
            alert("Error: Changing the game mode failed. Please try again.");
            console.error("An error occurred:", error);
          }
        }}
      />
    </>
  );
}

export default GameList;
