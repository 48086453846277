import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import MultiSelect from "./MultiSelect";

const GameEditForm = ({
  gameConfig,
  control,
  getValues,
  setValues,
  unregister,
  error,
  cryptos,
  stocks,
  gameData,
}) => {
  const [gameAssetType, setGameAssetType] = useState(getValues("gameAssetType"));
  const defaultGameAssetType = gameData?.gameAssetType;
  // const defaultStocks = gameData?.stocks?.map((e) => e?._id) || [];

  // console.log(cryptos?.length, stocks?.length, "GOT DATA DATER OPEN DIALOG");

  const [stocksData, setStocksData] = useState(stocks ?? []);
  const [cryptosData, setCryptosData] = useState(cryptos ?? []);

  useEffect(() => {
    if (!gameAssetType) {
      setGameAssetType(gameData?.gameAssetType);
    }
  }, [gameData]);

  useEffect(() => {
    setStocksData(stocks);
    setCryptosData(cryptos);
  }, [cryptos, stocks]);

  if (!gameData) {
    return <>Loading...</>;
  }

  return (
    <>
      {/* GAME ASSET TYPE SELECT */}
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        <Controller
          name="gameAssetType"
          control={control}
          defaultValue={defaultGameAssetType || ""} // Default to the first value or empty string
          rules={{ required: "Game asset type is required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              sx={{
                width: 180,
                "& .MuiOutlinedInput-root": {
                  // Input border color
                  "&.Mui-disabled": {
                    "& fieldset": {
                      borderColor: "rgba(255, 255, 255, 0.5) !important", // White border
                    },
                  },
                },
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "rgba(255, 255, 255, 0.5) !important", // White input text color
                },
                "& .MuiAutocomplete-popupIndicator": {
                  color: "rgba(255, 255, 255, 0.5) !important", // White dropdown icon
                },
                "& .MuiFormLabel-root": {
                  color: "rgba(255, 255, 255, 0.5) !important", // White label
                },
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: "rgba(255, 255, 255, 0.5) !important", // Border for input box
                },
              }}
              defaultValue={value}
              options={gameConfig?.gameAssetType?.map((type) => ({ label: type })) || []} // Convert ["CRYPTOS", "STOCKS"] to [{ label: "CRYPTOS" }, { label: "STOCKS" }]
              getOptionLabel={(option) => option?.label || ""} // Display the label
              isOptionEqualToValue={(option, v) => option?.label === v?.label} // Match options by label
              disabled
              onChange={(event, newValue) => {
                onChange(newValue?.label || ""); // Update React Hook Form's value
                setValues("stocks", []);
                setValues("cryptos", []);

                if (newValue?.label == "CRYPTOS") {
                  unregister("stocks");
                } else if (newValue?.label == "STOCKS") {
                  unregister("cryptos");
                }

                setGameAssetType(newValue?.label);
              }}
              value={
                gameConfig?.gameAssetType
                  ?.map((type) => ({ label: type }))
                  ?.find((option) => option.label === value) || null // Ensure value matches the option
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Game Asset Type"
                  variant="outlined"
                  sx={{
                    "& .MuiInputBase-root": {
                      height: 45,
                    },
                  }}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
        />
      </Box>

      {/* SELECT ALL CRYPTOS OR STOCKS */}
      {gameAssetType && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "100%",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Typography variant="body2" sx={{ color: "red" }}>
            {error.value ? error.value : ""}
          </Typography>
          <Box mt={2} width={"100%"}>
            {console.log(cryptos?.length, stocks?.length, "INSIDE COMPONENT", gameAssetType)}
            {gameAssetType == "CRYPTOS" && (
              <Controller
                name={"cryptos"}
                control={control}
                defaultValue={gameData?.cryptos || []}
                rules={{
                  required: `${"Crypto values are required"}`,
                }}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    key={"CRYPTOS"}
                    value={value}
                    items={cryptosData}
                    label={"Cryptos"}
                    selectAllLabel={"Select all cryptos"}
                    onChange={onChange}
                  />
                )}
              />
            )}

            {gameAssetType == "STOCKS" && (
              <Controller
                name={"stocks"}
                control={control}
                defaultValue={gameData?.stocks || []}
                rules={{
                  required: `${"Stocks values are required"}`,
                }}
                render={({ field: { onChange, value } }) => (
                  <MultiSelect
                    key={"STOCKS"}
                    value={value}
                    items={stocksData}
                    label={"Stocks"}
                    selectAllLabel={"Select all stocks"}
                    onChange={onChange}
                  />
                )}
              />
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

// Define prop types
// GameForm.propTypes = {
//   gameConfig: PropTypes.object.isRequired, // Array is expected
//   control: PropTypes.object.isRequired, // Object is expected
// };

export default GameEditForm;
