import {
  Backdrop,
  Box,
  Button,
  Card,
  Fade,
  Grid,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import AlertConfirm from "components/AlertConfirm";
import AlertMessage from "components/AlertMessage";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import React, { useState } from "react";
import * as apiService from "../../services/api/gameAPI";

const style = {
  position: "absolute",
  top: { xs: "25%", md: "50%" },
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: 400, sm: 700, lg: "50%" },
  boxShadow: 24,
  display: "flex",
  flexDirection: "column",
  height: { xs: 400, md: 230 },
  overflowY: "hidden",
};

const AddStickers = () => {
  const [open, setOpen] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [isAlertMessgOpen, setIsAlertMessg] = useState(false);

  const [sickerName, setSickerName] = useState("");

  const [loading, setLoading] = useState(false);

  const submit = () => {
    setAlertOpen(true);
  };

  const handleOpen = async () => {
    try {
      setOpen(true);
    } catch (error) {
      // Handle errors here

      console.error("Error fetching rule:", error);
    }
  };

  const handleClose = () => setOpen(false);

  return (
    <>
      <Button
        onClick={() => {
          handleOpen();
        }}
        variant="contained"
      >
        Add Stickers
      </Button>

      {/* Model */}
      <Modal
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        // aria-labelledby="modal-modal-title"
        // aria-describedby="modal-modal-description"
      >
        <Fade in={open}>
          <Box container sx={style.modal}>
            <Box sx={style}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Card
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "20px",
                      padding: 3,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        width: "100%",
                        alignItems: "center",
                        gap: "20px",
                      }}
                    >
                      <Typography
                        sx={{ m: 3 }}
                        textAlign="center"
                        id="transition-modal-title"
                        variant="h4"
                        component="h2"
                      >
                        Add Stickers
                      </Typography>

                      <MDBox>
                        <Stack
                          width={"100%"}
                          direction={{ xs: "column", md: "row" }}
                          marginBottom={3}
                          gap={2}
                          justifyContent={"center"}
                        >
                          <TextField
                            value={sickerName}
                            onChange={(e) => setSickerName(e.target.value)}
                            label="Sticker Name"
                            placeholder="Enter the sticker name"
                            sx={{ width: { md: 400 } }}
                          />

                          <MDButton
                            type="submit"
                            onClick={submit}
                            sx={{ textAlign: "center" }}
                            variant="gradient"
                            color="primary"
                          >
                            Add
                          </MDButton>
                        </Stack>{" "}
                      </MDBox>
                    </Box>
                  </Card>
                </Grid>
              </Grid>
            </Box>
            <AlertConfirm
              open={alertOpen}
              title="Create"
              context="Do you want to add stickers?"
              loading={loading}
              onClose={() => setAlertOpen(!alertOpen)}
              onAccept={async () => {
                try {
                  if (!sickerName) {
                    alert("Please enter sticker name");
                    return;
                  }

                  setLoading(true);

                  const res = await apiService.addSticker(sickerName);
                  console.log(res);
                  setAlertOpen(false);
                  setOpen(false);
                  setLoading(false);
                  alert("Sticker request sended successfully");
                } catch (error) {
                  console.log(error);
                  if (error?.response?.data?.error) {
                    alert(error?.response?.data?.error);
                  } else {
                    alert("Something went wrong!");
                  }
                }

                setLoading(false);
              }}
            />

            <AlertMessage
              open={isAlertMessgOpen}
              setOpen={setIsAlertMessg}
              severity="Error"
              message="Something went wrong!"
            />
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default AddStickers;
