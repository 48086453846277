import styled from "@emotion/styled";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import React, { useEffect } from "react";
import * as apiService from "../../../services/api/gameAPI";

const StyledTableHead = styled(TableHead)(() => ({
  display: "table-header-group",
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  color: theme.palette.common.white,
  borderBottom: "none",
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const _stocksTradingHourMockData = [
  {
    dayName: "Mon",
    date: "2024-12-09",
    openTime: "09:30:00",
    closeTime: "16:00:00",
    isHoliday: false,
  },
  {
    dayName: "Tue",
    date: "2024-12-10",
    openTime: "09:30:00",
    closeTime: "16:00:00",
    isHoliday: false,
  },
  {
    dayName: "Wed",
    date: "2024-12-11",
    openTime: "09:30:00",
    closeTime: "16:00:00",
    isHoliday: false,
  },
  {
    dayName: "Thu",
    date: "2024-12-12",
    openTime: "09:30:00",
    closeTime: "16:00:00",
    isHoliday: false,
  },
  {
    dayName: "Fri",
    date: "2024-12-13",
    openTime: "09:30:00",
    closeTime: "16:00:00",
    isHoliday: false,
  },
];

const StockTradingHour = () => {
  const [stocksTradingHour, setStocksTradingHour] = React.useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const response = await apiService.getConfigData();
      console.log(response?.data);
      setStocksTradingHour(response?.data?.data?.weeklyTradingHours ?? []);
    };
    fetchData();
  }, []);

  return (
    <DashboardLayout>
      <TableContainer>
        {stocksTradingHour?.length === 0 ? (
          <div
            style={{
              color: "#FFF",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "300px",
            }}
          >
            There are no records at the moment.
          </div>
        ) : (
          <Table>
            <StyledTableHead>
              <TableRow>
                <StyledTableCell>Day</StyledTableCell>
                <StyledTableCell> Open Time</StyledTableCell>
                <StyledTableCell>Close Time</StyledTableCell>
                <StyledTableCell>Is Holiday</StyledTableCell>
              </TableRow>
            </StyledTableHead>

            <TableBody>
              {stocksTradingHour?.map((data, i) => (
                <StyledTableRow key={data?.dayName + i}>
                  <StyledTableCell sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {data.dayName}
                  </StyledTableCell>
                  <StyledTableCell>{data?.openTime}</StyledTableCell>
                  <StyledTableCell>{data?.closeTime}</StyledTableCell>
                  <StyledTableCell>{data?.isHoliday ? "Holiday" : "Working day"}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </TableContainer>
    </DashboardLayout>
  );
};

export default StockTradingHour;
